<template>
  <el-main>
    <div>
      <el-button type="primary" size="small" @click="openDialog(1)">+ 添加充值产品</el-button>
      <el-button type="primary" size="small" @click="$router.push('/extension/gatherTweeters/rechargeNotice')">+ 设置充值须知</el-button>
    </div>
    <el-table style="margin-top: 20px" :data="packInfo.list" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id">
      <template slot="empty">
        <No />
      </template>
      <el-table-column label="编号" prop="id" align="center"></el-table-column>
      <el-table-column label="产品类型" align="center">
        <template v-slot="{ row }">
          {{ getTypeName(row.type) }}
        </template>
      </el-table-column>
      <el-table-column label="充值类型" align="center">
        <template v-slot="{ row }">
          {{ row.charge_type == 1 ? '快充' : '慢充' }}
        </template>
      </el-table-column>
      <el-table-column label="售价" align="center">
        <template v-slot="{ row }">￥{{ row.sell_price }}</template>
      </el-table-column>
      <el-table-column label="赠送积分" align="center">
        <template v-slot="{ row }">{{ row.integral }}</template>
      </el-table-column>
      <el-table-column label="原价" align="center">
        <template v-slot="{ row }">￥{{ row.official_price }}</template>
      </el-table-column>
      <el-table-column label="创建时间" align="center">
        <template v-slot="{ row }">
          {{ getDateformat(row.create_time) }}
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template v-slot="{ row }">
          <el-button type="text" @click="openDialog(2, row)">编辑</el-button>
          <el-button type="text" @click="deletePack(row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="packInfo.total" :page="packInfo.page" :pageNum="packInfo.rows" @updatePageNum="updateData"></Paging>
    <!-- 添加/编辑 -->
    <el-dialog title="操作" :visible.sync="showDialogFlag" width="500px">
      <el-form label-width="120px">
        <el-form-item label="产品类型">
          <el-select v-model="currentData.type" placeholder="请选择">
            <el-option v-for="item in typeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="充值类型">
          <el-radio-group v-model="currentData.charge_type">
            <el-radio :label="1">快充</el-radio>
            <el-radio :label="2">慢充</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="产品售价">
          <el-input v-model="currentData.sell_price"></el-input>
        </el-form-item>
        <el-form-item label="赠送积分">
          <el-input v-model="currentData.integral"></el-input>
        </el-form-item>
        <el-form-item label="产品原价">
          <el-input v-model="currentData.official_price"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showDialogFlag = false">取 消</el-button>
        <el-button type="primary" @click="sureDialogFn">确 定</el-button>
      </span>
    </el-dialog>
  </el-main>
</template>

<script>
import Paging from '@/components/paging.vue';
import { getDateformat } from '@/util/getDate.js';
export default {
  components: {
    Paging,
  },
  data() {
    return {
      getDateformat,
      packInfo: {
        page: 1,
        rows: 10,
        list: [],
        total: 0,
      },
      typeList: [
        { id: 1, name: '中国移动' },
        { id: 2, name: '中国联通' },
        { id: 3, name: '中国电信' },
        { id: 4, name: '中石化' },
        { id: 5, name: '中石油' },
        { id: 6, name: '京东e卡' },
        { id: 7, name: '视听会员' },
      ],
      showDialogFlag: false,
      currentData: {
        type: '',
        sell_price: '',
        official_price: '',
        charge_type: 1,
        integral: '',
      },
    };
  },
  created() {
    this.getPackList();
  },
  methods: {
    updateData(val, status) {
      if (status == 0) {
        this.packInfo.rows = val;
      } else {
        this.packInfo.page = val;
      }
      this.getPackList();
    },
    getPackList() {
      this.$axios
        .post(this.$api.yiyun.packList, {
          page: this.packInfo.page,
          rows: this.packInfo.rows,
        })
        .then(res => {
          if (res.code == 0) {
            this.packInfo.list = res.result.list;
            this.packInfo.total = res.result.total_number;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getTypeName(type) {
      let data = this.typeList.find(i => i.id == type) || { name: '异常' };
      return data.name;
    },
    // 打开弹窗
    openDialog(style, data) {
      if (style == 1) {
        this.currentData = {
          type: '',
          sell_price: '',
          official_price: '',
          integral: '',
          charge_type: 1,
        };
      }
      if (style == 2) {
        this.currentData = {
          id: data.id,
          type: Number(data.type),
          sell_price: data.sell_price,
          official_price: data.official_price,
          integral: data.integral,
          charge_type: data.charge_type,
        };
      }
      this.showDialogFlag = true;
    },
    // 弹窗确认按钮
    sureDialogFn() {
      let url = this.$api.yiyun.packAdd;
      let obj = {
        type: this.currentData.type,
        sell_price: this.currentData.sell_price,
        official_price: this.currentData.official_price,
        charge_type: this.currentData.charge_type,
        integral: this.currentData.integral,
        short_name: 'short_name',
        name: 'name',
        img: 'img',
      };
      if (this.currentData.id) {
        url = this.$api.yiyun.packEdit;
        obj.id = this.currentData.id;
      }
      this.$axios.post(url, obj).then(res => {
        if (res.code == 0) {
          this.$message.success('操作成功');
          this.getPackList();
          this.showDialogFlag = false;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    deletePack(data) {
      this.$confirm('此操作将删除该套餐, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          return this.$axios.post(this.$api.yiyun.packDelete, {
            id: data.id,
          });
        })
        .then(res => {
          if (res.code == 0) {
            this.$message.success('删除成功');
            this.getPackList();
          } else {
            this.$message.error(res.msg);
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background-color: #fff;
  min-height: 100%;
}
</style>
